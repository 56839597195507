import React from "react";
import loadable from "@loadable/component";
import Seo from "../components/Seo";
// import HeroSection from "../components/HeroSection";
// import HomeFeatures from "../components/HomeFeatures";

const HeroSection = loadable(() => import("../components/HeroSection"));
const Signup = loadable(() => import("../components/SignupForm"));

const IndexPage = () => (
  <React.Fragment>
    <Seo manualSlug="" />
    <HeroSection />
    <Signup />
  </React.Fragment>
);

export default IndexPage;
